/**
 * [Ref] - Denotes Pseudo Code Reference
 *
 * This component is Repay Service component.
 *
 * Author: Gowtham.H
 * Created Date: 07/08/2020
 */
/* Ref:20  */
/*Import Neccessary Imports */
import { Injectable } from '@angular/core';
import {APICallSettings} from '../constants/APICallSettings';
import { HeaderItem } from '../constants/HeaderItem';
import { environment } from 'src/environments/environment';
/* Ref:28 */
/* Service configs strings */
// const ServiceConfigs = {
//   appToken_Key: "Repay_AppToken",
//   sharedSecretKey_Key: "Repay_SharedSecretKey",
//   urlGetCheckOutFormId_Key: "Repay_URL_GetCheckOutFormId",
//   urlPayment_Key: "Repay_URL_Payment",
//   urlSchedulePaymentDetail_Key: "Repay_URL_SchedulePaymentDetail",
//   urlSchedulePaymentDelete_Key: "Repay_URL_SchedulePaymentDelete",
//   urlVoid_Key: "Repay_URL_Void",
//   urlRefund_Key: "Repay_URL_Refund",
//   api2_user_Key: "Repay_API2_User",
//   api2_secureToken_Key: "Repay_API2_SecureToken",
//   api2_urlPayment_Key: "Repay_API2_UrlPayment",
//   api2_urlRefund_Key: "Repay_API2_UrlRefund"
// };

@Injectable()
/* RefNo:22 */
/*repayIframeService Class*/
export class RepayIframeService {

  /*Variables Decalration which is used in Class*/
  public appToken: string;
  //public sharedSecretKey: string;
  public urlGetCheckOutFormId: string;
  public urlPayment: string;
  public repayEnvrionmentVar: string;
  public APISettings: APICallSettings = new APICallSettings();


 /* Ref:RC_PS_23  */
  /* Constructor declaration */
  constructor(

  ) {
    /*Binding key value while calling class */
    this.appToken = environment.appToken;
    this.urlGetCheckOutFormId = environment.urlGetCheckOutFormId;
    this.urlPayment = environment.urlPayment;
    this.repayEnvrionmentVar = environment.repayEnvironmentVar;
  }
  /* RefNo: RC_PS_24*/
  /*Get Checkout form ID*/
  /** @param paymentmethod */
  /** @param forScheduling */
  /** This method will return FormID */
  GetCheckOutFormId() {

    // let appsettingsobj =  new APICallSettings();
    this.APISettings.URL = this.urlGetCheckOutFormId;
    this.APISettings.HttpMethod = "POST";
    this.APISettings.ContentType = "application/json";
    this.APISettings.HeaderItem= (new HeaderItem("Authorization", "apptoken {" + this.appToken +"}"));
    // if (paymentmethod == 4) {
    //   /**if condition paymentmethod == PaymentMethod.find(val=> val.Auth).id */
    //   this.APISettings.Body = "{ " +
    //     "\"payment_method\": \"card\"," +
    //     "\"transaction_type\": \"auth\"" +
    //     "}";

    // } else {
    //   this.APISettings.Body = "{ " +
    //     "\"payment_method\": \"" + PaymentMethod.find(val => val.id == paymentmethod).key + "\"" +
    //     (forScheduling ? ",\"transaction_type\": \"scheduled_sale\"" : "") +
    //     "}";
    // }
    if(this.repayEnvrionmentVar == ""){
      this.APISettings.Body = "{ " +
      "\"payment_method\": \"card\"" + "," + "\"SaveCard\" : \"1\"" +
      "}"
    }
    else{
      this.APISettings.Body = "{ " +
      "\"payment_method\": \"card\"" + "," + 
      "  \"environment\" : \"" + this.repayEnvrionmentVar + "\"," +
      "\"SaveCard\" : \"1\"" +
      "}"
    }
    return this.APISettings;

  }
/* Ref:RC_PS_25  */
  /*CardSale method with following parameter */
  /**@param leaseId*/
  /**@param amount*/
  /**@param checkoutFormId*/
  /**This method will return redirect URL to Repay Framework */


  CardSale(leaseId, amount, checkoutFormId, redirectUrl) {
    var strUrlPayment = this.urlPayment + checkoutFormId + "/one-time-use-url";
    this.APISettings.URL = strUrlPayment;
    this.APISettings.HttpMethod = "POST";
    this.APISettings.ContentType = "application/json";
    this.APISettings.HeaderItem= (new HeaderItem("Authorization", "apptoken {" + this.appToken + "}"));
    if(this.repayEnvrionmentVar == ""){
      this.APISettings.Body =
      "{ " +
      "  \"amount\" : \"" + amount.toString() + "\"," +
      "  \"customer_id\" : \"" + leaseId + "\"," +
      "  \"transaction_type\" : \"sale\"," +
      "  \"waive_conv_fee\" : " + "\"" + "\"" + "," +
      "  \"CFV\" : \"" + "0" + "\"," +
      "  \"convenience_fee\" : \"" + "0" + "\"" + "," +
      "  \"redirect_url\" : \"" + redirectUrl + "\"," +
      "  \"InitialPayment\" : \"" + "1" + "\"" +
      // redirectUrl == "" ? "" : "  \"redirect_url\" : \"" + redirectUrl + "\"," +
      // "  \"State\" : \"" + States.find(val => val.id == convFee.StateId).name.toString().toUpperCase() + "\"," + /*should be empty*/
      // "  \"is_first_cc_payment\" : \"" + convFee.IsFirstCcPayment.toString().toLowerCase() + "\"," + /*should be empty*/
      // "  \"waive_conv_fee\" : " + convFee.Waive.toString().toLowerCase() + "," + /*should be empty*/
      // "  \"User\" : \"" + userName + "\"," + /*should be empty*/
      // "  \"LTETranId\" : \"" + "" + "\"," + /*should be empty*/

      "}";
    }
    else
    {
      this.APISettings.Body =
      "{ " +
      "  \"amount\" : \"" + amount.toString() + "\"," +
      "  \"customer_id\" : \"" + leaseId + "\"," +
      "  \"transaction_type\" : \"sale\"," +
      "  \"waive_conv_fee\" : " + "\"" + "\"" + "," +
      "  \"CFV\" : \"" + "0" + "\"," +
      "  \"convenience_fee\" : \"" + "0" + "\"" + "," +
      "  \"redirect_url\" : \"" + redirectUrl + "\"," +
      "  \"environment\" : \"" + this.repayEnvrionmentVar + "\"," +
      "  \"InitialPayment\" : \"" + "1" + "\"" +
      // redirectUrl == "" ? "" : "  \"redirect_url\" : \"" + redirectUrl + "\"," +
      // "  \"State\" : \"" + States.find(val => val.id == convFee.StateId).name.toString().toUpperCase() + "\"," + /*should be empty*/
      // "  \"is_first_cc_payment\" : \"" + convFee.IsFirstCcPayment.toString().toLowerCase() + "\"," + /*should be empty*/
      // "  \"waive_conv_fee\" : " + convFee.Waive.toString().toLowerCase() + "," + /*should be empty*/
      // "  \"User\" : \"" + userName + "\"," + /*should be empty*/
      // "  \"LTETranId\" : \"" + "" + "\"," + /*should be empty*/

      "}";
    }

      return this.APISettings;
  }



}

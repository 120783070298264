export enum EventType {
    unauthorized = 'unauthorized',
    invalid_pid = 'invalid_pid',
    cancel = 'cancel',
    success = 'success',
    pid_used = 'pid_used',
    pid_expired = 'pid_expired',
    perm_error = 'perm_error',
    tmp_error = 'tmp_error',
    token_expired = 'token_expired'
}

// tslint:disable-next-line:variable-name
export const Regexp_Patterns = {
  Name: '^[a-zA-Z-\' \/]{2,30}$',
  Alphanumeric: '^[a-zA-Z0-9]*$',
  City: '^[a-zA-Z\ \/]*$',
  Address: '^[a-zA-Z0-9-.\'\, \/]{1,30}$',
  Zipcode: '\\b\\d{5}\\b',
  Phone: '\\(?([0-9]{3})\\)?([ ]?)([0-9]{3})?([ .-]?)([0-9]{4})',
  // tslint:disable-next-line:max-line-length
  Email: `^(([^<>()\\[\\]\\.,;:\\s@\\"]+(\\.[^<>()\\[\\]\\.,;:\\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\\]\\.,;:\\s@\\"]+\\.)+[^<>()[\\]\\.,;:\\s@\\"]{2,})$`,
  CheckingAccountNumber: '^[0-9]{4,17}$',
  BankRoutingNumber: '^[0-9]{9}$',
  Password: '^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#\$%\^&\*])(?=.*[0-9]).{8,24}$',
  password1:'^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])[a-zA-Z][a-zA-Z0-9]{6,}$'
};

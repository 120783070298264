import { Injectable } from '@angular/core';

@Injectable()
export class UrlService {

  constructor() { }

  getAPIBaseURL() {
    let hostName = window.location.hostname;
    if (hostName === 'localhost') {
      hostName = 'https://dev-addpay.rentacenter.com';
      return hostName;
    }
    else{
    return  '';
    }
  }
}

import { Injectable } from '@angular/core';
import { UrlService } from './url.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { APICallSettings } from '../constants/APICallSettings';
import { CreditCard } from '../constants/CreditCard';
import { CreateAccountReq } from '../constants/CreateAccountReq';
import { TokenResponse } from '../constants/TokenResponse';
import { environment } from 'src/environments/environment';
import { PaymentRequestInfo } from '../constants/PaymentRequestInfo';

@Injectable()
export class DataService {

  private getRequestInfo = '/v1/customers/payment/recipient';
  private savePaymentMethodPath = '/v1/customers/customerId/payment/method';
  private createAccountPath = '/v1/customers/createAccount';
  private verifyAccountPath = '/v1/customers/verifyAccount';
  private initialPaymentPath = '/v1/customers/initialPayment';
  private esbPath = '/api/payment/makeInitialPayment/ecom';
  private pmtSegGenPath = '/v1/customers/pmtSeqGen';
  private creditCardPaymentPath = '/v1/customers/creditCardPayment';
  private adjustAcceptPaymentPath = '/v1/customers/acceptAdjustPayment';
  private payAckPath = '/v1/customers/paymentAcknowledgement';
  private sendEmailPath = '/v1/customers/sendEmail';
  private updatePmtStatusPath = '/v1/customers/updatePaymentStatus';


  constructor(
    private http: HttpClient,
    private urlService: UrlService) { }

  getRequestInformation(paymentTokenId: string) {
    const httpOptions = this.buildRequestInfoHeaders(paymentTokenId);
    const url = this.urlService.getAPIBaseURL() + this.getRequestInfo;
    console.log("getRequestInformation = ", {"url": url, "httpOptions": httpOptions});
    return this.http.get(url, httpOptions)
      .pipe(
        catchError(this.handleError(this.getRequestInfo, []))
      );
  }

  private buildRequestInfoHeaders(paymentTokenId) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        pid: paymentTokenId,
        correlationid: paymentTokenId
      })
    };

    return httpOptions;
  }

  savePaymentMethod(cardInfo: CreditCard, customerId, paymentTokenId, messageId, token) {
    const httpOptions = this.buildSavePaymentHeaders(messageId, token);
    const uri = this.urlService.getAPIBaseURL() + this.savePaymentMethodPath;
    const url = uri.replace('customerId', customerId);
    const externalOrderId = messageId.substring(1, 20);
    const body = {
      cardToken: cardInfo.cardToken,
      cardExpiration: cardInfo.cardExpiration,
      type: cardInfo.type,
      cardLastDigits: cardInfo.lastDigits,
      pid: paymentTokenId,
      orderId: externalOrderId
    };
    return this.http.post(url, body, httpOptions)
      .pipe(
        catchError(this.handleError('savePaymentMethod', []))
      );
  }

  private buildSavePaymentHeaders(messageId, token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        correlationid: messageId,
        Authorization: token
      })
    };

    return httpOptions;
  }

  private buildEsbPaymentHeaders(reqInfo : PaymentRequestInfo) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Revision': '1.1',
        'ClientOriginator': '87',
        'correlationid': reqInfo.messageId,
        'Authorization': reqInfo.token,
        'MessageID': 'APS005-' + reqInfo.messageId,
        'ClientID': reqInfo.clientId,
        'LocationNumber': reqInfo.storeId,
        'ClientAPIKey': environment.esbClientAPIKey,
        'ClientSecret': environment.esbClientSecret
      })
    };

    return httpOptions;
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      return of(error as T);
    };
  }

  //create epay Account
  createAccountMethod( paymentTokenId, token, messageId) {
    const httpOptions = this.buildSavePaymentHeaders(messageId, token);
    const url = this.urlService.getAPIBaseURL() + this.createAccountPath;
    const body = {
      pid: paymentTokenId
    };
    return this.http.post(url, body, httpOptions)
      .pipe(
        catchError(this.handleError('createAccountMethod', []))
      );
  }

   //verify epay Account
   verifyAccountMethod(req: CreateAccountReq, paymentTokenId, token, messageId) {
    const httpOptions = this.buildSavePaymentHeaders(messageId, token);
    const url = this.urlService.getAPIBaseURL() + this.verifyAccountPath;
    const body = {
      userName: req.userName,
      password: req.password,
      pid: paymentTokenId
    };
    return this.http.post(url, body, httpOptions)
      .pipe(
        catchError(this.handleError('createAccountMethod', []))
      );
  }

  initialPaymentMethod(cardInfo: CreditCard, reqInfo : PaymentRequestInfo) {

    const httpOptions = this.buildEsbPaymentHeaders(reqInfo);
    const url = environment.esbHost + this.esbPath;
    const body = {
      MessageType: "OPS203",
		  RegionID: "1",
		  ClientLocationNumber: reqInfo.storeId? reqInfo.storeId.toString() : "",
		  ClientSource: "1",
		  LocationNumber:  reqInfo.storeId ? reqInfo.storeId.toString() : "",
		  ApplyPaymentRequest: {
			CustomerID: reqInfo.engagementId ? reqInfo.engagementId.toString() : "",
			//PaymentID:  requestBody.paymentToken,
			PaymentAmount: reqInfo.amount? reqInfo.amount.toString() : "",
      PaymentTokenID: cardInfo.cardToken,
      ExpirationMonth: cardInfo.cardExpirationMonth,
      ExpirationYear: cardInfo.cardExpirationYear,
      CardType: cardInfo.type,
      LastFour: cardInfo.lastDigits,
			PromoCode: reqInfo.promoCode ? reqInfo.promoCode.toString() : "1",
		}

    };
    return this.http.post(url, body, httpOptions)
      .pipe(
        catchError(this.handleError('initialPaymentMethod', []))
      );
  }

  /* RefNo:26  */
  /*Getting checkoutformid from database using post method*/
  /**@param settings with type of APICallSettings*/

  async getCheckoutFormId(settings: APICallSettings) {

    //debugger;
    const httpOptions = this.buildHeader(settings.HeaderItem.Value);

    const url = settings.URL;
    const body = (settings.Body != null || settings.Body != "") ? settings.Body : "";
    let result;
    /**Returning URL as a response ,if it is error return error messege*/
    try {
      result = await this.http.post(url, body, httpOptions).toPromise().then((data: any) => {
        console.log(data.checkout_form_id);
        // alert(data.checkout_form_id);
        return data.checkout_form_id
      });
    }
    catch (error) {
      this.handleError('GetCheckoutFormID', [])
      console.log(error);
    }


    //debugger;
    return result

  }
  /* Ref:27 */
  /**Forming header using header value */
  buildHeader(headervalue) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: headervalue

      })
    };
    return httpOptions
  }
  /* RefNo:28 */
  /*Getting URL from database using post method*/
  async getRepayURL(settings: APICallSettings) {
    //debugger;

    const httpOptions = this.buildHeader(settings.HeaderItem.Value);

    const url = settings.URL;
    const body = (settings.Body != null || settings.Body != "") ? settings.Body : "";

    let result;
    try {
      /**Returning URL as a response ,if it is error return error messege*/
      result = await this.http.post(url, body, httpOptions).toPromise().then((data: any) => {
        // console.log(data.checkout_form_id);
        return data.url
      });

    }
    catch (error) {
      this.handleError('GeRepaytUrl', [])
      console.log(error);
    }

    //debugger;
    return result
  }

  //Payment Sequence Generator - PAY006
  paymentSequenceGenService( paymentTokenId, messageId, token ) {
    console.log("Inside paymentSequenceGenService");
    
    const httpOptions = this.buildSavePaymentHeaders(messageId, token);
    const url = this.urlService.getAPIBaseURL() + this.pmtSegGenPath;
    const body = {
      pid: paymentTokenId
    };
    return this.http.post(url, body, httpOptions)
      .pipe(
        catchError(this.handleError('paymentSequenceGenService', []))
      );
  }

    //Payment Processing service - PAY001
    creditCardPaymentService( paymentTokenId, messageId, token, cardInfo: CreditCard, externalId, clientOriginator, address : any, storeId,paymentDetails:any) {
      const httpOptions = this.buildSavePaymentHeaders(messageId, token);
      const url = this.urlService.getAPIBaseURL() + this.creditCardPaymentPath;
      const body = {
        pid: paymentTokenId,
        cardToken: cardInfo.cardToken,
        expirationDate: cardInfo.cardExpiration,
        cardType: cardInfo.type,
        lastFour: cardInfo.lastDigits,
        externalId: externalId,
        clientOriginator: clientOriginator,
        billingAddress: address,
        storeId: storeId,
        paymentDetails:paymentDetails
      };
      const result = this.http.post(url, body, httpOptions)
      console.log("return result:: ",result);
      return result
        .pipe(
          catchError(this.handleError('creditCardPaymentService', []))
        );
  }

     //AdjustAcceptPayment Enterprise Service
     adjustAcceptPaymentService( paymentTokenId, messageId, token, request) {
      const httpOptions = this.buildSavePaymentHeaders(messageId, token);
      const url = this.urlService.getAPIBaseURL() + this.adjustAcceptPaymentPath;
      const body = {
        pid: paymentTokenId,
        request: request
      };
      console.log("adjustAcceptPaymentService url:: ",url);      
      return this.http.post(url, body, httpOptions)
        .pipe(
          catchError(this.handleError('adjustAcceptPaymentService', []))
        );
    }

     //payment Acknowledgement ESB Service
     paymentAckService( paymentTokenId, messageId, token, request) {
      const httpOptions = this.buildSavePaymentHeaders(messageId, token);
      const url = this.urlService.getAPIBaseURL() + this.payAckPath;
      const body = {
        pid: paymentTokenId,
        partyId: request.partyId,
        externalId: request.externalId,
        externalOrderId:request.externalOrderId
      };
      return this.http.post(url, body, httpOptions)
        .pipe(
          catchError(this.handleError('paymentAckService', []))
        );
    }

     //send email ESB Service
     sendEmailService( paymentTokenId, messageId, token, receiptId) {
      const httpOptions = this.buildSavePaymentHeaders(messageId, token);
      const url = this.urlService.getAPIBaseURL() + this.sendEmailPath;
      const body = {
        pid: paymentTokenId,
        receiptId: receiptId
      };
      return this.http.post(url, body, httpOptions)
        .pipe(
          catchError(this.handleError('sendEmailService', []))
        );
    }

     //update Payment Status Service update DB Service
     updatePaymentStatusService( paymentTokenId, messageId, token, request, partyId) {
      const httpOptions = this.buildSavePaymentHeaders(messageId, token);
      const url = this.urlService.getAPIBaseURL() + this.updatePmtStatusPath;
      const body = {
        pid: paymentTokenId,
        paymentStatus: request.paymentStatus,
        error: request.error,
        customerId: partyId
      };
      return this.http.post(url, body, httpOptions)
        .pipe(
          catchError(this.handleError('updatePaymentStatusService', []))
        );
    }

}

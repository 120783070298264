import { CreditCardsTypes } from './credit-cards-types';

export interface ICreditCard {
    lastDigits: string;
    cardExpiration: string;
    cardToken: string;
    type: string;
    cardType: string;
}

export class CreditCard implements ICreditCard {

    lastDigits: string;
    cardExpiration: string;
    cardToken: string;
    type: string;
    cardExpirationMonth: string;
    cardExpirationYear: string;
    cardType: string;

    constructor(response = null) {
        this.lastDigits = response && response.lastFour || null;
        this.cardToken = response && response.paypageRegistrationId || null;
        this.cardExpiration = response && (response.expMonth + response.expYear) || null;
        this.type = response && CreditCardsTypes.find(val => val.short === response.type).id || null;
        this.cardExpirationMonth = response && response.cardExpirationMonth || null;
        this.cardExpirationYear = response && response.cardExpirationYear || null;
        this.cardType = response && CreditCardsTypes.find(val => val.short === response.type).abbreviation || null;
    }
}

//RC_PS_7 Import Neccessary Imports
import { Component, OnInit} from '@angular/core';
//import { SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DataService } from '../shared/services/data.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { RepayIframeService } from '../shared/services/repay-iframe.service';
import { APICallSettings } from '../shared/constants/APICallSettings';


/*RC_PS_8 Defines the Component and Shows the HTML as it passes HTML as the path for Template URL */
@Component({
  selector: 'app-payment-processor',
  templateUrl: './repay-payment-processor.component.html',
  styleUrls: ['./repay-payment-processor.component.scss']
})

/**Declare class with name of RepayPaymentProcessing inherit of OnInit*/
export class RepayPaymentProcessing implements OnInit {
  //RC_PS_9 Defining the Global Variables
  processCCForm: FormGroup;
  private URL: string;
  private LeaseID: any;
  private ProcessAmount: any = null;
  private RedirectURL: any;
  private repayredirect: any;
  private RepayRedirectURL: any;
  private ProcessCCContinuebtn;
  private pid: any;
  private paymentRequestInfo: any;
  private disableBtn: boolean = false;



  //RC_PS_10 Defining the Constructor which sets the intial variables value.
  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private repayIframeService: RepayIframeService,
    private spinner: NgxSpinnerService,
    private dataService: DataService,


  ) {



  }
  /**Reference RC_PS_11 ngOnInit is an angualr life cycle method triggered on page load*/
  ngOnInit() {
    this.spinner.show('loading');
    this.route.queryParams.subscribe(params => {


      //this.LeaseID = params['LA_Id']; //Testing Purposes
      //this.pid = params.get('pid');
      this.repayredirect = params['repayredirect'];

    });
    this.getCustomerId(this.pid);
    this.initForm();
  }
  /*RefNo:RC_PS_12*/
  /* Getting Customer Id using pid */
  getCustomerId(pid) {
    const pidReponse = this.route.paramMap.pipe(
      switchMap(params => {

        return this.dataService.getRequestInformation(params.get('pid'));
      }));
    if (pidReponse) {
      pidReponse.subscribe(response => {
        this.paymentRequestInfo = response;
        if (this.paymentRequestInfo) {
          this.LeaseID = this.paymentRequestInfo.engagementId != null || this.paymentRequestInfo.engagementId != "" ? this.paymentRequestInfo.engagementId : "";
          this.ProcessAmount = this.paymentRequestInfo.amount != null || this.paymentRequestInfo.amount != "" ? this.paymentRequestInfo.amount.toFixed(2) : "";
          this.RepayRedirectURL = window.location.href + "?repayredirect=1"
          this.RedirectURL = this.paymentRequestInfo.callbackURL != null || this.paymentRequestInfo.callbackURL != "" || this.paymentRequestInfo.callbackURL != "undefined" ? this.paymentRequestInfo.callbackURL : "https://www.preferredlease.com/";
        }
        this.spinner.hide('loading');
        if (this.RedirectURL != null || this.RedirectURL != undefined || this.RedirectURL != "undefined" || this.RedirectURL != "") {
          this.SetRedirectURL();
        }
      }, (error) => {
        console.log('err', error);
      });
    }


  }


  /*RefNo: RC_PS_13*/
  /*checking the process cc amount is zero or not*/
  initForm() {
    
    this.processCCForm = this.fb.group({
      processamount: [{
        value: this.ProcessAmount,
        disabled: true
      },
      [Validators.required, Validators.pattern('[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+){1,8}$')]
      ]
    });

  }
  /**Required Field validation and regresion validation for validation fields for process CC*/



  /*RefNo:RC_PS_14*/
  /* Validate form return true or false,if it is valid ,it will return true otherwise it will return false */


  validateFormField(formField: any): boolean {
    return formField.valid || formField.untouched;
  }

  /*RefNo: RC_PS_15*/
  /* Validate form return true or false,if it is valid ,it will return true otherwise it will return false */
  ProcessvalidateField(fieldName: string): boolean {
    return this.validateFormField(this.processCCForm.get(fieldName));
  }


  /*RefNo: RC_PS_16
 Checks wheter the amount legnth is greater than 8 and
 not a number in the keyup and if it is it throws an error*/

  /* RefNo: RC_PS_17 Amount field validation for Process CC Form*/






  /*RefNo: RC_PS_18*/
  /**Function which is invoked when Repay Sale Click*/
  /**This method will be invoke cardsale method which is in RepayIframeService */
  /**@param  LeaseID*/
  /**@param  Amount*/
  /**@param checkoutFormID*/

  async RepaySaleRadButton_Click(event) {
   
    /**disable the continue button and changing the value from Continue to Loading*/
    this.ProcessCCContinuebtn = event;
    this.ProcessCCContinuebtn.textContent = 'Loading...';
    this.disableBtn = true;
    let checkoutFormID = await this.getCheckoutFormId();
    //let repaysaleobj = new repay.repayIframeService();
    /*Need to add check based on usertype*/
    let Settingsobj: APICallSettings = this.repayIframeService.CardSale(
      this.LeaseID,
      this.ProcessAmount,
      checkoutFormID,
      this.RepayRedirectURL
    )
    
    let apiResponseUrl = await this.dataService.getRepayURL(Settingsobj);
    this.URL = apiResponseUrl.toString();


    /**Redirect to repay to url which we get as a response from getRepayURL*/
    window.location.assign(this.URL);
  }




  /*RefNo: RC_PS_19*/
  /**Getting Checkout form ID as a response from services function using post method*/
  async getCheckoutFormId() {
    
    let checkoutISettings = this.repayIframeService.GetCheckOutFormId();
    let apiResponse = await this.dataService.getCheckoutFormId(checkoutISettings);
    let apiResponseString = apiResponse.toString();
    //let checkoutFormId = apiresponsestring.substring(apiresponsestring.indexOf(':') + 1).replace("}", "").replace("\"", "").trim();
    return apiResponseString;
  }

  SetRedirectURL() {
    if (this.repayredirect == "1" || this.repayredirect == 1) {
      window.location.href = this.RedirectURL;
    }
  }




}

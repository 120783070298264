import { Injectable } from '@angular/core';

import { v4 as uuid } from 'uuid';
import { environment } from '../../../environments/environment';

declare let LitlePayframeClient: any;

const VantivConfigs = {
  PAY_PAGE_ID: environment.payPageId,
  REPORT_GROUP: 'ANOW-CP',
  // STYLE: 'RacVantiv',
  STYLE: 'RacVantiv',
  MERCHANT_ID: environment.merchantId,
  TIMEOUT: 15000,
  HEIGHT: '200px',
  WIDTH: '560px',
  CVV_PLACEHOLDER: 'CVV',
  ACCOUNT_NO_PLACEHOLDER: 'Card Number',
};


@Injectable()
export class VantivConfig {

  config: {
    paypageId: string;
    style: string;
    reportGroup: string;
    div: string;
    timeout: number;
    height: string;
    width: string,
    showCvv: boolean;
    numYears: string;
   placeholderText: {
      cvv: string,
      accountNumber: string
    };
    tabIndex: {
      cvv: number,
      accountNumber: number,
      expMonth: number,
      expYear: number
    };
    content: {
      accountNumber: string,
      expDate: string,
      accountNumberLabelText: string,
      cvv: string,
      expDateLabelText: string
    };
    labels: {
      accountNumber: string,
      expDate: string,
      accountNumberLabelText: string,
      cvv: string,
      expDateLabelText: string
    };
  styles: {
    height: string,
    width: string
    // Add other style overrides here
    };
    enhancedUxFeatures: {
      inlineFieldValidations: boolean
    };
    callback: any;
    inputsEmptyCallback: any;
  } = {
    paypageId: VantivConfigs.PAY_PAGE_ID,
    style: VantivConfigs.STYLE,
    reportGroup: VantivConfigs.REPORT_GROUP,
    div: '',
    timeout: VantivConfigs.TIMEOUT,
    height: VantivConfigs.HEIGHT,
    width: VantivConfigs.WIDTH,
    showCvv: true,
    numYears: '20',
    placeholderText: {
      cvv: VantivConfigs.CVV_PLACEHOLDER,
      accountNumber: VantivConfigs.ACCOUNT_NO_PLACEHOLDER,
    },
    content: {
      accountNumber: 'Card Number*',
      expDate: 'Expiration Date*',
      accountNumberLabelText:'accountNumberLabelText',
      cvv: 'CVV*',
      expDateLabelText: 'expDateLabelText'
    },
    labels: {
      accountNumber: 'Card Number*',
      expDate: 'Expiration Date*',
      accountNumberLabelText:'accountNumberLabelText',
      cvv: 'CVV*',
      expDateLabelText:'expDateLabelText'
    },
    styles: {
      height: '200px',
      width: '550px',
    // Add other style overrides here
    },
    tabIndex: {
      cvv: 1,
      accountNumber: 2,
      expMonth: 3,
      expYear: 4,
    },
    enhancedUxFeatures: {
      inlineFieldValidations: true,
    },
    callback: 0,
    inputsEmptyCallback: (data) => { },
  };

  constructor() {}

  getConfig(containerId: string, callback: any) {
    this.config.div = containerId;
    this.config.callback = callback;
    return this.config;
  }
}

@Injectable()
export class VantivIframeService {

  private vantivIFrame: any;
  constructor(
    private vantivConfig: VantivConfig,
   // private sharedActions: SharedActions,
  ) {
    this.vantivCallback = this.vantivCallback.bind(this);
  }

  // loadVantivIFrame(containerId: string, callback: any) {
  //   if (typeof LitlePayframeClient === undefined) {
  //     console.error('LitlePayframeClient is not loaded yet');
  //     return;
  //   }
  //   this.vantivCallback = callback;
  //   this.vantivIFrame = new LitlePayframeClient(this.vantivConfig.getConfig(containerId, this.vantivCallback));
  // }

  loadVantivIFrame(containerId: string, callback: any) {
    this.vantivCallback = callback;
    console.log("LitlePayframeClient")
    this.vantivIFrame = new LitlePayframeClient(this.vantivConfig.getConfig(containerId, this.vantivCallback));
  }

  getRegistrationId(uniqueId) {
  //  this.sharedActions.showSpinner();
    this.vantivIFrame.getPaypageRegistrationId({
      id: VantivConfigs.MERCHANT_ID,
      orderId: uniqueId.substring(1, 20)
    });
  }

  private vantivCallback(response) {
    if (response.response === '870') {
      return;
    }
  }

}

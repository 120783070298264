
export class CreateAccountReq {

    userName: string;
    password: string;
    securityQuestion: string;
    securityAnswer: string;

    constructor() {
        this.userName = null;
        this.password = null;
        this.securityQuestion = null;
        this.securityAnswer = null;
    }

}

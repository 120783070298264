import { Injectable } from '@angular/core';

const eComplishConfigs = {
    Action: 'getRegID',
    AllowACH: 'N',
    Language: 'en'
};

@Injectable()
export class eComplishConfig {
    private customerNumber: string;
    private storeId: string;
    data: {
        action: string;
        pageID: string;
        allowACH: string;
        language: string;
        customerNumber: string;
        storeNumber: string;
    } = {

            action: eComplishConfigs.Action,
            pageID: this.getPaypageId(),
            allowACH: eComplishConfigs.AllowACH,
            language: eComplishConfigs.Language,
            customerNumber: this.customerNumber,
            storeNumber: this.storeId

        };

    constructor() { }

    getPaypageId() {
        var date = new Date();
        var payPageID = date.getTime().toString();
        return payPageID;
    }
    getCustomerDetails(customerNumber: string, storeId : string) {
        this.data.customerNumber = customerNumber;
        this.data.storeNumber = storeId;
        return;
    }
}

@Injectable()
export class eComplishIframeService {
  private isEventFired = false;
    constructor( 
        private eComplishConfig: eComplishConfig
    ) { 
      this.eComplishcallBack = this.eComplishcallBack.bind(this);
    }

    getPageRegID(reqValues, callBack : any, customerNumber,storeNumber) {
      this.isEventFired = false;
      this.eComplishcallBack = callBack;
      this.eComplishConfig.getCustomerDetails(customerNumber,storeNumber);
        console.log("Posting message to Ecomplish PayPage.");
        if (window.addEventListener) {
          window.addEventListener("message", this.receiveMessage.bind(this), false)
        } else {
          (<any>window).attachEvent("onmessage", this.receiveMessage.bind(this));
        }
        reqValues = this.eComplishConfig.data;
        const element: HTMLIFrameElement = document.getElementById("ecompIframe") as HTMLIFrameElement;
        const iframe = element.contentWindow;
        if (iframe !== null) {
          iframe.postMessage(JSON.stringify(reqValues), "*");
        }
      }

      receiveMessage = function (event) {
        let response = null;
                 try {
                   if(!this.isEventFired){
          const response = JSON.parse(event.data)
          console.log("Response from Ecomplish PayPage: " + response);
          this.isEventFired = true;
          this.eComplishcallBack(response);
          window.removeEventListener("message", this.receiveMessage.bind(this), false);
                   }
        } catch (c) {
          console.log("Exception while invoking PayPage: " + response.responseText);
        }
      }
    private  eComplishcallBack(response) {
        if (response.responseCode == '1') {
          return;
        }
      }
    }


import { Component, OnInit, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { DataService } from '../shared/services/data.service';
import { VantivIframeService } from '../shared/services/vantiv-iframe.service';
import { PaymentRequestInfo } from '../shared/constants/PaymentRequestInfo';
import { CreditCard } from '../shared/constants/CreditCard';
import { EventType } from '../shared/constants/Event-Type';
import { MessageService } from '../message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Regexp_Patterns } from '../shared/constants/regex-patterns';
import { usStates } from 'src/app/shared/constants/us-states';

@Component({
  selector: 'app-pmt-id-processor',
  templateUrl: './pmt-id-processor.component.html',
  styleUrls: ['./pmt-id-processor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PmtIdProcessorComponent implements OnInit, OnChanges {

  model: Model = new Model();
  private showFrame = true;
  private showInvalidPid = false;
  private showInvalidToken = false;
  private disableSubmitBtn = false;
  private paymentRequestInfo: PaymentRequestInfo;
  private responseCode;
  private cardInfo: CreditCard;
  private initialPaymentResponseObj: any;
  private paymentSeqGenRespObj: any;
  private paymentSuccessful = false;
  private receiptGenerated = false;
  private showSubmit = true;
  private showSFContainer = false;
  private minDate: Date;
  private SFFlow = false;
  private schedule = null;
  private payDate = null;
  events: string[] = [];
  private clientOriginator = null;
  private noPayDate = false;
  private showAddressError = false;
  private formSubmitAttempt = false;
  addressForm: FormGroup;
  usStates: any[];

  constructor(
    private vantivService: VantivIframeService,
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private router: Router,
    private formBuilder: FormBuilder,
    public utilsService: UtilsService) {
    this.handleVantivCallback = this.handleVantivCallback.bind(this);
    this.minDate = new Date();
    this.minDate.setDate(new Date().getDate() + 1);
    this.usStates = usStates;
  }

  initForm() {
    this.addressForm = this.formBuilder.group({
      addressFirstLine: ['', [Validators.required, Validators.pattern(Regexp_Patterns.Address)]],
      addressSecondLine: ['', [, Validators.pattern(Regexp_Patterns.Address)]],
      city: ['', [Validators.required, Validators.pattern(Regexp_Patterns.City)]],
      state: ['', [Validators.required,]],
      zipCode: ['', [Validators.required, Validators.pattern(Regexp_Patterns.Zipcode)]]
    });
  }

  validateField(fieldName: string): boolean {
    return (!this.addressForm.get(fieldName).valid && this.addressForm.get(fieldName).touched) ||
      (this.addressForm.get(fieldName).untouched && this.formSubmitAttempt)
    //this.utilsService.validateFormField(this.addressForm.get(fieldName));
  }

  ngOnInit(): void {
    this.initForm();
    this.showFrame = true;
    this.showSubmit = true;
    this.showInvalidPid = false;
    this.showInvalidToken = false;
    this.paymentSuccessful = false;
    this.receiptGenerated = false;
    this.spinner.show('loading');
    const resp$ = this.route.paramMap.pipe(
      switchMap(params => {
        this.showFrame = true;
        return this.dataService.getRequestInformation(params.get('pid'));
      }
      ));
    if (resp$) {
      console.log("resp$: ", resp$);
      resp$.subscribe(response => {
        console.log("this.paymentRequestInfo before mapping ::", this.paymentRequestInfo);

        this.paymentRequestInfo = response as PaymentRequestInfo;
        console.log("this.paymentRequestInfo after mapping ::", this.paymentRequestInfo);
        let navigationExtras: NavigationExtras = {
          queryParams: {
            'pid': this.paymentRequestInfo.paymentTokenId ? this.paymentRequestInfo.paymentTokenId : null
          }
        };
        /* if(this.paymentRequestInfo.clientId && this.paymentRequestInfo.clientId === '1' ){
           this.router.navigate(['/payment-processing' , this.paymentRequestInfo.paymentTokenId]);
         }
         */
        /*
        RC_PS_5 When the url is first loaded the app-routing-module will route to this component
        so then this else if will send the repay path back to the app-routing-module.
        */
        if (this.paymentRequestInfo.clientId && this.paymentRequestInfo.clientId === '3') {
          this.router.navigate(['/repay-processing', this.paymentRequestInfo.paymentTokenId]);
        }
        else
          if (this.paymentRequestInfo && this.paymentRequestInfo.partyId && (this.paymentRequestInfo.callbackURL ||
            this.paymentRequestInfo.clientId === '6' || this.paymentRequestInfo.clientId === '8')) {
            this.showFrame = true;
            this.vantivService.loadVantivIFrame('ccframe', this.handleVantivCallback);
            this.spinner.hide('loading');
            // clientId - 5 SF flow
            if (this.paymentRequestInfo.clientId && (this.paymentRequestInfo.clientId === '7')) {
              this.showSFContainer = true;
              this.SFFlow = true;
            }
          } else {
            const httpErrorResponse = response as HttpErrorResponse;
            this.spinner.hide('loading');
            this.showFrame = false;
            if (httpErrorResponse && httpErrorResponse.status) {
              const status = httpErrorResponse.status;
              if (status === 401 || status === 403) {
                this.showInvalidToken = true;
              } else {
                this.showInvalidPid = true;
              }
            } else {
              this.spinner.hide('loading');
              this.showFrame = false;
              this.showInvalidPid = true;
            }
          }
      }, (error) => {
        console.log('err', error);
        throw error;
      });
    }
  }

  get show() {
    return this.showFrame;
  }

  ngOnChanges(changes) {
  }

  handleVantivCallback(response: any): void {
    this.responseCode = response.response;
    if (this.responseCode === '870') {
      this.cardInfo = new CreditCard(response);
    }
    // hack to register the callback
    if (document.getElementById('hack') !== null) {
      document.getElementById('hack').click();
    }
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    this.events.push(`${type}: ${event.value}`);
    this.payDate = this.formatDate(event.value);
    this.noPayDate = false;
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');

  }
  processVantivCallback() {

    if (this.responseCode === '870') {
      if (this.paymentRequestInfo.clientId && this.paymentRequestInfo.clientId === '1') {
        // For Charge After to call ops203
        this.handleInitialPayment(this.cardInfo);
        this.spinner.hide('saving');
        this.spinner.show('suc');
      }
      // for SIMS to call Adjust Payment
      console.log("this.paymentRequestInfo.clientId =", this.paymentRequestInfo.clientId);
      console.log("this.paymentRequestInfo", this.paymentRequestInfo);

      if (this.paymentRequestInfo.clientId && (this.paymentRequestInfo.clientId === '4' || this.paymentRequestInfo.clientId === '6' || this.paymentRequestInfo.clientId === '8' || this.SFFlow)) {
        console.log("this.paymentRequestInfo.paymentStatus", this.paymentRequestInfo.paymentStatus);
        if (this.paymentRequestInfo.paymentStatus === 'Pending' || this.paymentRequestInfo.paymentStatus === 'Failed') {
          if (this.SFFlow) {
            console.log(this.model.paySchedule);
            this.schedule = this.model.paySchedule;
          }
          // For SIMS call Adjust payment
          console.log("handleSimsPayment this.cardInfo", this.cardInfo);
          this.handleSimsPayment(this.cardInfo);
          this.spinner.hide('saving');
          this.spinner.show('propay');
        } else {
          let msg = 'Payment was already processed for this customer.';
          this.messageService.add(msg);
          this.spinner.hide('saving');
        }
      }
      else {
        this.dataService.savePaymentMethod(this.cardInfo, this.paymentRequestInfo.partyId,
          this.paymentRequestInfo.paymentTokenId, this.paymentRequestInfo.messageId, this.paymentRequestInfo.token)
          .subscribe(saveResponse => {
            this.handleESBResponse(saveResponse);
          }, (error) => {
            console.log(error);
            this.processCallbackWithParams(EventType.perm_error, null);
          });
        this.spinner.hide('saving');
        this.spinner.show('suc');
      }

    } else {
      this.spinner.hide('saving');
      this.handleVantivErrorResponse();
    }
  }

  handleVantivErrorResponse() {
    if (this.responseCode === '871' || this.responseCode === '872' || this.responseCode ===
      '873' || this.responseCode === '874' || this.responseCode === '876') {
      // Recoverable error caused by user mis-typing their credit card
      this.messageService.add('Please check and re-enter your credit card number and try again.');
      this.disableSubmitBtn = false;
    } else if (this.responseCode === '881' || this.responseCode === '882' || this.responseCode === 883) {
      // Recoverable error caused by user mis-typing their credit card
      this.messageService.add('Please check and re-enter your card validation number and try again.');
      this.disableSubmitBtn = false;
    } else if (this.responseCode === '884') {
      this.processCallbackWithParams(EventType.tmp_error, null);
    } else {
      // Non-recoverable or unknown error code
      this.messageService.add('We are experiencing technical difficulties. Please try again');
      this.disableSubmitBtn = false;
    }
  }

  handleESBResponse(saveESBResponse) {
    console.log(saveESBResponse);
    let msg = 'Error while saving the token, please re-try';
    if (saveESBResponse) {
      const updateCustomerResponse = saveESBResponse.UpdateCustomerResponse;
      if (updateCustomerResponse && updateCustomerResponse.ApprovalResponse && updateCustomerResponse.ApprovalResponse === '1') {
        const paymentMethodId = updateCustomerResponse.PaymentMethodID;
        this.processCallbackWithParams(EventType.success, paymentMethodId);
      } else if (updateCustomerResponse && updateCustomerResponse.ApprovalResponse && updateCustomerResponse.ApprovalResponse !== '1') {
        this.spinner.hide('suc');
        if (updateCustomerResponse.StatusCodeDetail) {
          msg = msg + ': ' + updateCustomerResponse.StatusCodeDetail;
        }
        this.messageService.add(msg);
      } else if (updateCustomerResponse && updateCustomerResponse.StatusCode && updateCustomerResponse.StatusCode !== '200') {
        this.spinner.hide('suc');
        if (updateCustomerResponse.StatusCodeDetail) {
          msg = msg + ': ' + updateCustomerResponse.StatusCodeDetail;
        }
        this.messageService.add(msg);
      } else {
        const httpErrorResponse = saveESBResponse as HttpErrorResponse;
        if (httpErrorResponse && httpErrorResponse.status) {
          const status = httpErrorResponse.status;
          if (status === 401 || status === 403) {
            this.processCallbackWithParams(EventType.token_expired, null);
          }
        }
        this.spinner.hide('suc');
        msg = msg + ': Caused by unkown issue';
        this.messageService.add(msg);
      }
      this.disableSubmitBtn = false;
    }
  }

  handleSubmit(e: any): void {
    if (this.SFFlow && this.payDate == null) {
      this.noPayDate = true;
      document.body.scrollTop = 200;
      document.documentElement.scrollTop = 200;
    }
    else if (this.SFFlow && (this.addressForm.controls.addressFirstLine.value === null || this.addressForm.controls.city.value === null ||
      this.addressForm.controls.state.value === null || this.addressForm.controls.zipCode.value === null ||
      this.addressForm.controls.addressFirstLine.value.length === 0 ||
      this.addressForm.controls.city.value.length === 0 ||
      this.addressForm.controls.state.value.length === 0 || this.addressForm.controls.zipCode.value.length === 0)) {
      document.body.scrollTop = 300;
      document.documentElement.scrollTop = 300;
      this.showAddressError = true;
      this.formSubmitAttempt = true;
    }
    else if (this.SFFlow && (this.addressForm.controls.addressFirstLine.value !== null || this.addressForm.controls.city.value !== null ||
      this.addressForm.controls.state.value !== null || this.addressForm.controls.zipCode.value !== null ||
      this.addressForm.controls.addressFirstLine.value.length !== 0 ||
      this.addressForm.controls.city.value.length !== 0 ||
      this.addressForm.controls.state.value.length !== 0 || this.addressForm.controls.zipCode.value.length !== 0)) {
      this.showAddressError = false;
      this.formSubmitAttempt = false;
      this.disableSubmitBtn = true;
      this.spinner.show('saving');
      this.vantivService.getRegistrationId(this.paymentRequestInfo.messageId);
    }
    else {
      this.disableSubmitBtn = true;
      this.spinner.show('saving');
      this.vantivService.getRegistrationId(this.paymentRequestInfo.messageId);
    }
  }


  get invalidPid() {
    return this.showInvalidPid;
  }

  get invalidToken() {
    return this.showInvalidToken;
  }

  get disableBtn() {
    return this.disableSubmitBtn;
  }

  processCallbackWithParams(eventType: EventType, paymentMethodId) {
    let callbackURL = this.paymentRequestInfo.callbackURL;
    if (callbackURL && !callbackURL.startsWith('http://') && !callbackURL.startsWith('https://')) {
      callbackURL = 'https://' + callbackURL;
    }
    window.location.href = callbackURL
      + '?customerid=' + this.paymentRequestInfo.partyId
      + '&event_type=' + eventType
      + (paymentMethodId ? '&pmthdid=' + paymentMethodId : '');
  }

  handleInitialPayment(cardInfo) {
    this.messageService.clear();
    let cardExpiration = this.cardInfo.cardExpiration;
    if (cardExpiration != null || cardExpiration != 'undefined') {
      this.cardInfo.cardExpirationMonth = cardExpiration.slice(0, 2);
      this.cardInfo.cardExpirationYear = cardExpiration.slice(2, 4);
    }
    this.dataService.initialPaymentMethod(cardInfo, this.paymentRequestInfo)
      .subscribe(initialPaymentResponse => {
        this.spinner.hide('suc');
        this.initialPaymentResponseObj = initialPaymentResponse;
        if (this.initialPaymentResponseObj.StatusCode === '200') {
          this.handleInitialPaymentResponse(this.initialPaymentResponseObj);
        }
        else {
          const httpErrorResponse = initialPaymentResponse as HttpErrorResponse;
          let msg = 'Error while processing the payment. Please try again later.';
          if (httpErrorResponse && httpErrorResponse.status) {
            if (httpErrorResponse.error || httpErrorResponse.error != null || httpErrorResponse.error != undefined) {
              if (httpErrorResponse.error.StatusCodeDetail || httpErrorResponse.error.StatusCodeDetail != null || httpErrorResponse.error.StatusCodeDetail != undefined)
                this.messageService.add(msg + httpErrorResponse.error.StatusCodeDetail);
              else
                this.messageService.add(msg);
            }
          }
          else {
            this.messageService.add(msg);
          }
        }
      }, (error) => {
        //let msg = 'Error while processing the payment. Please try again later.';
        // this.messageService.add(msg);
        this.processEcomCallbackWithParams(EventType.perm_error);
        console.log(error);
      });
  }

  handleInitialPaymentResponse(Response) {
    console.log(Response);
    if (Response && Response.StatusCode === '200') {
      //if(Response.TransactionID && (Response.TransactionID != null || Response.TransactionID != undefined))
      this.processEcomCallbackWithParams(EventType.success);
      // process call back with Params
    }
    else {
      let msg = 'Error while processing the payment. Please try again later.';
      this.messageService.add(msg);
    }
  }

  processEcomCallbackWithParams(eventType: EventType) {
    let callbackURL = this.paymentRequestInfo.callbackURL;
    let navigateUrl = null;
    const substring = "?";
    if (callbackURL && !callbackURL.startsWith('http://') && !callbackURL.startsWith('https://')) {
      callbackURL = 'https://' + callbackURL;
    }
    if (callbackURL.includes(substring)) {
      navigateUrl = callbackURL
        + '&customerid=' + this.paymentRequestInfo.partyId
        + '&event_type=' + eventType;
    }
    else
      navigateUrl = callbackURL
        + '?customerid=' + this.paymentRequestInfo.partyId
        + '&event_type=' + eventType;
    window.location.href = navigateUrl;
  }

  private getBillingAddressValues() {
    return {
      address1: this.addressForm.get('addressFirstLine').value,
      address2: this.addressForm.get('addressSecondLine').value,
      city: this.addressForm.get('city').value,
      stateProvince: this.addressForm.get('state').value,
      postalCode: this.addressForm.get('zipCode').value
    };
  }

  // for sims
  handleSimsPayment(cardInfo) {
    console.log("Inside handleSimsPayment");

    this.messageService.clear();
    var address = null;
    if (this.SFFlow) {
      this.clientOriginator = '196';
      address = this.getBillingAddressValues();
    }
    else {
      this.clientOriginator = '0';
    }

    console.log("pay det", this.paymentRequestInfo.paymentDetails);

    this.dataService.creditCardPaymentService(this.paymentRequestInfo.paymentTokenId,
      this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, cardInfo,
      this.paymentRequestInfo.paymentTokenId, this.clientOriginator, address, this.paymentRequestInfo.storeId, this.paymentRequestInfo.paymentDetails).subscribe(creditCardPaymentResp => {
        this.handleCCPaymentResponse(creditCardPaymentResp, cardInfo);
      }, (error) => {
        this.spinner.hide('genrept');
        this.spinner.hide('propay');
        console.log(error);
      });

    // this.dataService.paymentSequenceGenService(this.paymentRequestInfo.paymentTokenId,
    //   this.paymentRequestInfo.messageId, this.paymentRequestInfo.token)
    //   .subscribe(paymentSeqGenResponse => {
    //     this.paymentSeqGenRespObj = paymentSeqGenResponse;
    //     const paymentSequenece = this.paymentSeqGenRespObj.Body;
    //     if (paymentSequenece && paymentSequenece != null &&
    //       paymentSequenece != undefined && paymentSequenece.SequenceValue) {
    //         if(this.SFFlow){
    //           this.clientOriginator = '196';
    //          address = this.getBillingAddressValues();
    //         }
    //         else {
    //         this.clientOriginator = '0';
    //         }
    //         console.log("pay det",this.paymentRequestInfo.paymentDetails);
    //       this.dataService.creditCardPaymentService(this.paymentRequestInfo.paymentTokenId,
    //         this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, cardInfo,
    //         paymentSequenece.SequenceValue, this.clientOriginator, address,this.paymentRequestInfo.storeId,this.paymentRequestInfo.paymentDetails).subscribe(creditCardPaymentResp => {
    //           this.handleCCPaymentResponse(creditCardPaymentResp, cardInfo);
    //         }, (error) => {
    //           this.spinner.hide('genrept');
    //           this.spinner.hide('propay');   
    //           console.log(error);
    //         });
    //     }
    //     else {
    //       this.spinner.hide('propay');
    //       const httpErrorResponse = paymentSeqGenResponse as HttpErrorResponse;
    //       let msg = 'Error while processing the payment. Please try again later.';
    //       if (httpErrorResponse && httpErrorResponse.status) {
    //         if (httpErrorResponse.error || httpErrorResponse.error != null || httpErrorResponse.error != undefined) {
    //           if (httpErrorResponse.error.StatusCodeDetail || httpErrorResponse.error.StatusCodeDetail != null || httpErrorResponse.error.StatusCodeDetail != undefined)
    //             this.messageService.add(msg + httpErrorResponse.error.StatusCodeDetail);
    //           else
    //             this.messageService.add(msg);
    //         }
    //       }
    //       else {
    //         this.messageService.add(msg);
    //       }
    //     }
    //   }, (error) => {
    //     //let msg = 'Error while processing the payment. Please try again later.';
    //     // this.messageService.add(msg);
    //     console.log(error);
    //   });

  }

  handleCCPaymentResponse(creditCardPaymentResp: any, cardInfo: CreditCard) {
    console.log("Inside handleCCPaymentResponse creditCardPaymentResp:: ", creditCardPaymentResp);

    //console.log(creditCardPaymentResp);
    if (creditCardPaymentResp) {
      // const creditCardPaymentResp = creditCardPaymentResp;
      if ((creditCardPaymentResp.statusCode && creditCardPaymentResp.statusCode === 'A') ||
        (creditCardPaymentResp.PAY_CCP_Response && creditCardPaymentResp.PAY_CCP_Response.Body && creditCardPaymentResp.PAY_CCP_Response.Body.StatusInfo
          && creditCardPaymentResp.PAY_CCP_Response.Body.StatusInfo.StatusCodeDetail
          && creditCardPaymentResp.PAY_CCP_Response.Body.StatusInfo.StatusCodeDetail === 'Approved')) {
        console.log("Inside handleCCPaymentResponse cardInfo:: ", creditCardPaymentResp);
        if (!this.SFFlow) {
          console.log("Inside !SFFlow", this.SFFlow);
          if (creditCardPaymentResp.tokenInfo.authNumber) {
            // RACPAD Flow - 6
            // RACCOMMS flow - 8
            if (this.paymentRequestInfo.clientId && (this.paymentRequestInfo.clientId === '6' || this.paymentRequestInfo.clientId === '8')) {
              console.log("Inside handleCCPaymentResponse if ", this.paymentRequestInfo.clientId);
              var json_string = null;
              if (this.paymentRequestInfo.paymentDetails != null) {
                json_string = this.paymentRequestInfo.paymentDetails;
                var json_obj = JSON.parse(json_string);
                var cardExpFormatted = '';
                if (cardInfo.cardExpiration && cardInfo.cardExpiration.length == 4) {
                  let expMonth = cardInfo.cardExpiration.substring(0, 2);
                  let expYear = cardInfo.cardExpiration.substring(2, 4);
                  cardExpFormatted = expMonth + '/' + expYear;
                }
                // const amount = this.paymentRequestInfo.amount.toString();
                json_obj.cardDetails = {
                  cardSeq: creditCardPaymentResp.extTransactionId,
                  cardAuthNumber: creditCardPaymentResp.tokenInfo.authNumber,
                  cardToken: creditCardPaymentResp.tokenInfo.paymentToken != null ? creditCardPaymentResp.tokenInfo.paymentToken : cardInfo.cardToken,
                  cardType: cardInfo.type,
                  cardLastFour: cardInfo.lastDigits
                };
                json_string = JSON.stringify(json_obj);
                var jsonReq = JSON.parse(json_string);
                console.log(json_string);
                console.log(jsonReq);
                // call accept payment for RACPAD Client - AcceptAdjustPayment Lamda
                this.dataService.adjustAcceptPaymentService(this.paymentRequestInfo.paymentTokenId,
                  this.paymentRequestInfo.messageId, this.paymentRequestInfo.token,
                  jsonReq).subscribe(acceptPaymentResp => {
                    this.handleAcceptPaymentResponseforRacpad(acceptPaymentResp, creditCardPaymentResp.extTransactionId, creditCardPaymentResp.externalOrderId);
                  }, (error) => {
                    let msg = 'Error while processing the payment. Please try again later.';
                    this.messageService.add(msg);
                    console.log(error);
                  });
              }

            }
            // SIMS Flow
            else {
              console.log("Inside handleCCPaymentResponse else ", this.paymentRequestInfo.clientId);
              var json_string = null;
              if (this.paymentRequestInfo.paymentDetails != null) {
                json_string = this.paymentRequestInfo.paymentDetails;
                var json_obj = JSON.parse(json_string);
                const amount = this.paymentRequestInfo.amount.toString();
                json_obj.cardAuthInfo = {
                  paymentAmount: amount,
                  paymentType: "CC",
                  cardType: cardInfo.cardType,
                  authorizationNumber: creditCardPaymentResp.tokenInfo.authNumber,
                  cardToken: creditCardPaymentResp.tokenInfo.paymentToken != null ? creditCardPaymentResp.tokenInfo.paymentToken : cardInfo.cardToken,
                  referenceNumber: creditCardPaymentResp.extTransactionId,
                  creditType: "CRD",
                  cardLastFour: cardInfo.lastDigits,
                  lastFour: cardInfo['lastDigits'],
                  transactionType: "MNL"
                };
                json_string = JSON.stringify(json_obj);
                var jsonReq = JSON.parse(json_string);
                //console.log(json_string);
                //console.log(jsonReq);
                // call adjust accept payment
                this.dataService.adjustAcceptPaymentService(this.paymentRequestInfo.paymentTokenId,
                  this.paymentRequestInfo.messageId, this.paymentRequestInfo.token,
                  jsonReq).subscribe(acceptAdjustPaymentResp => {
                    this.handleAcceptAdjustPaymentResponse(acceptAdjustPaymentResp, creditCardPaymentResp.extTransactionId, creditCardPaymentResp.externalOrderId);
                  }, (error) => {
                    this.spinner.hide('genrept');
                    this.spinner.hide('propay');
                    let msg = 'Error while processing the payment. Please try again later.';
                    this.messageService.add(msg);
                    console.log(error);
                  });
              }
            }
          }
        }

        //condition for clientId 7 
        if (this.SFFlow) {
          console.log("Inside SFFlow", this.SFFlow);
          if ((creditCardPaymentResp.PAY_CCP_Response && creditCardPaymentResp.PAY_CCP_Response.Body && creditCardPaymentResp.PAY_CCP_Response.Body.TransactionID &&
            creditCardPaymentResp.PAY_CCP_Response.Body.TransactionID != null && creditCardPaymentResp.PAY_CCP_Response.Body.TransactionID != undefined)
            || (creditCardPaymentResp.extTransactionId && creditCardPaymentResp.extTransactionId != null && creditCardPaymentResp.extTransactionId != undefined)) {
            var request = {
              paymentStatus: 'Completed',
              error: 'Payment was successful.'
            }
            console.log("updatePaymentStatusService call:: ");
            this.dataService.updatePaymentStatusService(this.paymentRequestInfo.paymentTokenId,
              this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, request, this.paymentRequestInfo.partyId).subscribe(updateResponse => {
              }, (error) => {
                console.log(error);
              });
            // The clientId = 7, will return new callback params
            console.log("processSFNewCallbackWithParams ::");
            this.processSFNewCallbackWithParams(EventType.success, creditCardPaymentResp, cardInfo);
            // The clientId = 5, will return exist callback params
            // else {
            //   console.log("Inside clientId is 5 = ", this.paymentRequestInfo.clientId);
            //   this.processSFCallbackWithParams(EventType.success, creditCardPaymentResp, cardInfo);
            // }
          }
        }
      }
      else {
        this.spinner.hide('genrept');
        this.spinner.hide('propay');
        console.log("error in handleCCPaymentResponse:: ", cardInfo);
        let msg = 'Error while processing the payment. Please try again later.';
        this.messageService.add(msg);
      }
      this.disableSubmitBtn = false;
    }
  }

  handleAcceptAdjustPaymentResponse(acceptAdjustPaymentResp, externalTransactionID, externalOrderId) {
    if (acceptAdjustPaymentResp && acceptAdjustPaymentResp.ReceiptResponse) {
      var response = acceptAdjustPaymentResp.ReceiptResponse;
      var receiptId = null;
      if (response.receiptId && response.receiptId != null && response.enterpriseResponse) {
        receiptId = response.receiptId;
        var resp = response.enterpriseResponse;
        if (resp && resp != null && resp.returnCode && resp.returnCode != null && resp.returnCode === '0') {
          // if(acceptAdjustPaymentResp) is 200 call
          this.spinner.hide('propay');
          this.paymentSuccessful = true;
          var request = {
            paymentStatus: 'Completed',
            error: 'Payment was successful.'
          }
          this.spinner.show('genrept');
          this.dataService.updatePaymentStatusService(this.paymentRequestInfo.paymentTokenId,
            this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, request, this.paymentRequestInfo.partyId).subscribe(updateResponse => {
            }, (error) => {
              console.log(error);
            });
          var packRequest = {
            partyId: this.paymentRequestInfo.partyId,
            externalId: externalTransactionID,
            externalOrderId: externalOrderId
          }
          if (receiptId != null) {
            if (this.paymentRequestInfo.emailAddress != null &&
              this.paymentRequestInfo.emailAddress !== 'null') {
              this.dataService.sendEmailService(this.paymentRequestInfo.paymentTokenId,
                this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, receiptId).subscribe(sendEmailResponse => {
                  this.handleSendEmailResponse(sendEmailResponse);
                }, (error) => {
                  console.log(error);
                });
            }
            else {
              let msg = 'Payment was successful. Please contact the help desk for the receipt.';
              this.messageService.add(msg);
              this.spinner.hide('genrept');
              this.spinner.show('propost');
            }
          } else {
            this.spinner.hide('genrept');
            this.spinner.hide('propay');
          }
          // this.dataService.paymentAckService(this.paymentRequestInfo.paymentTokenId,
          //   this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, packRequest).subscribe(packResponse => {
          //     this.handlePackResponse(packResponse);
          //   }, (error) => {
          //     console.log(error);
          //   });
        }
      }
    }
    else {
      this.spinner.hide('propay');
      let message = 'Payment was not successful. Accept Adjust Payment ES service was not successful.'
      this.messageService.add(message);
      let msg = null;
      if (acceptAdjustPaymentResp) {
        if (acceptAdjustPaymentResp.error) {
          var errorResponseObj = acceptAdjustPaymentResp.error;
          if (errorResponseObj.errors !== null && errorResponseObj.errors !== undefined) {
            var errorResponse = errorResponseObj.errors;
            for (var i = 0; i < errorResponse.length; i++) {
              msg = + errorResponse.message;
            }
          }
        }
      }
      var request = {
        paymentStatus: 'Failed',
        error: 'Payment was not successful. Accept Adjust Payment ES service was not successful. ' + msg
      }
      console.log('Payment was not successful. Accept Adjust Payment ES service was not successful. ' + msg);
      this.dataService.updatePaymentStatusService(this.paymentRequestInfo.paymentTokenId,
        this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, request, this.paymentRequestInfo.partyId).subscribe(updateResponse => {
        }, (error) => {
          console.log(error);
        });
    }
  }

  handleAcceptPaymentResponseforRacpad(acceptPaymentResp, externalTransactionID, externalOrderId) {
    if (acceptPaymentResp && !acceptPaymentResp.error) {
      this.spinner.hide('propay');
      this.paymentSuccessful = true;
      var request = {
        paymentStatus: 'Completed',
        error: 'Payment was successful.'
      }
      this.dataService.updatePaymentStatusService(this.paymentRequestInfo.paymentTokenId,
        this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, request, this.paymentRequestInfo.partyId).subscribe(updateResponse => {
        }, (error) => {
          console.log(error);
        });
      var packRequest = {
        partyId: this.paymentRequestInfo.partyId,
        externalId: externalTransactionID,
        externalOrderId: externalOrderId
      }
      // this.dataService.paymentAckService(this.paymentRequestInfo.paymentTokenId,
      //   this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, packRequest).subscribe(packResponse => {
      //     this.handlePackResponse(packResponse);
      //   }, (error) => {
      //     console.log(error);
      //   });
      var receiptId = acceptPaymentResp.receiptPath;
      if (receiptId && receiptId != null) {
        if (this.paymentRequestInfo.emailAddress != null &&
          this.paymentRequestInfo.emailAddress !== 'null') {
          this.spinner.show('genrept');
          this.dataService.sendEmailService(this.paymentRequestInfo.paymentTokenId,
            this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, receiptId).subscribe(sendEmailResponse => {
              this.handleSendEmailResponse(sendEmailResponse);
            }, (error) => {
              console.log(error);
            });
        }
        else {
          let msg = 'Payment was successful. Please contact the help desk for the receipt.';
          this.messageService.add(msg);
          // console.log(error);
          this.spinner.hide('genrept');
          this.spinner.show('propost');
        }
      } else {
        this.spinner.hide('propay');
        // let msg = 'Payment was successful.';
        // this.messageService.add(msg);
      }
      this.paymentSuccessful = true;
    }
    else {
      this.spinner.hide('propay');
      let message = 'Payment was not successful. Accept payment service was not successful.'
      this.messageService.add(message);
      let msg = null;
      if (acceptPaymentResp) {
        if (acceptPaymentResp.error) {
          var error = acceptPaymentResp.error;
          if (error.errors !== null && error.errors !== undefined) {
            for (var i = 0; i < error.errors.length; i++) {
              msg = + error.errors.message;
            }
          }
        }
      }
      var request = {
        paymentStatus: 'Failed',
        error: 'Payment was not successful. Accept payment service was not successful. ' + msg
      }
      console.log('Payment was not successful. Accept payment service was not successful. ' + msg);
      this.dataService.updatePaymentStatusService(this.paymentRequestInfo.paymentTokenId,
        this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, request, this.paymentRequestInfo.partyId).subscribe(updateResponse => {
        }, (error) => {
          console.log(error);
        });
    }
  }


  handlePackResponse(packResponse) {
    if (packResponse && packResponse != null && packResponse != undefined
      && packResponse.error && packResponse.error != null && packResponse.error != undefined) {
      this.spinner.hide('propost');
      var errorResponse = packResponse.error;
      if (errorResponse && errorResponse != null) {
        var errorRespDetail = errorResponse.OPS_PACK_Response;
        if (errorRespDetail && errorRespDetail != null &&
          errorRespDetail != undefined && errorRespDetail.StatusCode && errorRespDetail.StatusCode != null
          && errorRespDetail.StatusCode !== '200'
          && errorRespDetail.StatusCodeDetail && errorRespDetail.StatusCodeDetail != null) {
          var request = {
            paymentStatus: 'Completed.',
            error: 'Payment was successful. Pack failed with ' + errorRespDetail.StatusCodeDetail
          }
          console.log('Payment was successful. Pack failed with ' + errorRespDetail.StatusCodeDetail);
          //let message = 'Payment was successful. Pack failed with ' + errorRespDetail.StatusCodeDetail;
          //this.messageService.add(message);
          this.dataService.updatePaymentStatusService(this.paymentRequestInfo.paymentTokenId,
            this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, request, this.paymentRequestInfo.partyId).subscribe(updateResponse => {
            }, (error) => {
              console.log(error);
            });
        }
      }
    }
    else if (packResponse && packResponse != null && packResponse != undefined) {
      this.spinner.hide('propost');
      var response = packResponse.OPS_PACK_Response;
      if (response.StatusCode && response.StatusCode != null && response.StatusCode === '200') {
        // pack is successful.
      }
      else {
      }
    }
  }

  handleSendEmailResponse(sendEmailResponse) {
    if (sendEmailResponse && sendEmailResponse.StatusCode && sendEmailResponse.StatusCode === '200') {
      // send Email is successful.
      this.spinner.hide('genrept');
      this.spinner.show('propost');

      if (document.getElementById('ccframe') !== null) {
        var frame = document.getElementById("ccframe");
        frame.parentNode.removeChild(frame);

      }
      this.showSubmit = false;
      this.paymentSuccessful = false;
      this.receiptGenerated = true;
      this.spinner.hide('propost');
    }
    else {
      this.spinner.hide('genrept');
      this.spinner.show('propost');
      var errorResponse = sendEmailResponse.error;
      if (errorResponse && errorResponse != null && errorResponse != undefined
        && errorResponse.StatusCode && errorResponse.StatusCode !== '200' &&
        errorResponse.StatusMessage && errorResponse.StatusMessage != null) {
        var request = {
          paymentStatus: 'Completed.',
          error: 'Payment was successful. Send Email failed with ' + errorResponse.StatusMessage
        }
        console.log('Payment was successful. Send Email failed with ' + errorResponse.StatusMessage);
        let message = 'Payment was successful. Please contact the help desk for the receipt.';
        this.messageService.add(message);
        this.dataService.updatePaymentStatusService(this.paymentRequestInfo.paymentTokenId,
          this.paymentRequestInfo.messageId, this.paymentRequestInfo.token, request, this.paymentRequestInfo.partyId).subscribe(updateResponse => {
          }, (error) => {
            console.log(error);
          });
      }
    }
  }

  processSFCallbackWithParams(eventType: EventType, creditCardPaymentResp, cardInfo) {
    console.log("Inside processSFCallbackWithParams");
    let callbackURL = this.paymentRequestInfo.callbackURL;
    let navigateUrl = null;
    const substring = "?";
    if (callbackURL && !callbackURL.startsWith('http://') && !callbackURL.startsWith('https://')) {
      callbackURL = 'https://' + callbackURL;
    }

    if (callbackURL.includes(substring)) {
      navigateUrl = callbackURL
        + '&cid=' + this.paymentRequestInfo.partyId
        + '&event_type=' + eventType
        + '&cType=' + cardInfo.type
        + '&cdtType=' + '1'
        + '&tT=' + '2'
        + '&tId=' + creditCardPaymentResp.PAY_CCP_Response.Body.TransactionID
        + '&pSch=' + this.schedule
        + '&pDt=' + this.payDate
    }
    else {
      navigateUrl = callbackURL
        + '&cid=' + this.paymentRequestInfo.partyId
        + '&event_type=' + eventType
        + '&cType=' + cardInfo.type
        + '&cdtType=' + '1'
        + '&tT=' + '2'
        + '&tId=' + creditCardPaymentResp.PAY_CCP_Response.Body.TransactionID
        + '&pSch=' + this.schedule
        + '&pDt=' + this.payDate
    }
    console.log("navigateUrl : " + navigateUrl);
    window.location.href = navigateUrl;
  }

  processSFNewCallbackWithParams(eventType: EventType, creditCardPaymentResp, cardInfo) {
    console.log("Inside processSFNewCallbackWithParams");
    let paymentToken = creditCardPaymentResp.tokenInfo.paymentToken != null ? creditCardPaymentResp.tokenInfo.paymentToken : cardInfo.cardToken
    let callbackURL = this.paymentRequestInfo.callbackURL;
    let navigateUrl = null;
    const substring = "?";
    if (callbackURL && !callbackURL.startsWith('http://') && !callbackURL.startsWith('https://')) {
      callbackURL = 'https://' + callbackURL;
    }

    if (callbackURL.includes(substring)) {
      navigateUrl = callbackURL
        + '&cid=' + this.paymentRequestInfo.partyId
        + '&event_type=' + eventType
        + '&cType=' + cardInfo.type
        + '&cdtType=' + cardInfo.cardType
        + '&extT=' + creditCardPaymentResp.extTransactionId
        + '&authT=' + creditCardPaymentResp.tokenInfo.authTransId
        + '&lastFour=' + cardInfo.lastDigits
        + '&crdT=' + paymentToken
        + '&autN=' + creditCardPaymentResp.tokenInfo.authNumber
        + '&status=' + creditCardPaymentResp.statusCode
        + '&pSch=' + this.schedule
        + '&sR=' + creditCardPaymentResp.statusReason
        + '&pDt=' + this.payDate
    }
    else {
      navigateUrl = callbackURL
        + '&cid=' + this.paymentRequestInfo.partyId
        + '&event_type=' + eventType
        + '&cType=' + cardInfo.type
        + '&cdtType=' + cardInfo.cardType
        + '&extT=' + creditCardPaymentResp.extTransactionId
        + '&authT=' + creditCardPaymentResp.tokenInfo.authTransId
        + '&lastFour=' + cardInfo.lastDigits
        + '&crdT=' + paymentToken
        + '&autN=' + creditCardPaymentResp.tokenInfo.authNumber
        + '&status=' + creditCardPaymentResp.statusCode
        + '&pSch=' + this.schedule
        + '&sR=' + creditCardPaymentResp.statusReason
        + '&pDt=' + this.payDate
    }
    console.log("navigateUrl : " + navigateUrl);
    window.location.href = navigateUrl;
  }

}

export class Model {
  paySchedule: string = "W";
}

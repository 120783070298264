// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  payPageId: '!payPageIdValue',
  merchantId: '!merchantIdValue',
  eComplishHost: '!eComplishHostValue',
  appToken: '!appTokenValue',
  urlGetCheckOutFormId: '!urlGetCheckOutFormIdValue',
  urlPayment: '!urlPaymentValue',
  repayEnvironmentVar: '!repayEnvironmentVarValue',
  esbHost: '!esbHost',
  esbClientAPIKey:'!esbClientAPIKey',
  esbClientSecret:'!esbClientSecret'  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import {HeaderItem} from './HeaderItem'
export class APICallSettings {

    URL : string;
    Body : string;
    HttpMethod : string;
    ContentType: string;
    HeaderItem:HeaderItem;

    constructor() {
        this.URL = "";
        this.Body = "";
        this.HttpMethod = "";
        this.ContentType = "";
    }

}
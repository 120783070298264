import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PmtIdProcessorComponent } from './pmt-id-processor/pmt-id-processor.component';
import { AppComponent } from './app.component';
import { PaymentProcessingComponent } from './payment-processing/payment-processing.component';
import { RepayPaymentProcessing } from './repay-payment-processor/repay-payment-processor.component';

const routes: Routes = [
  { path: '', redirectTo: '/pids', pathMatch: 'full'},
 // { path: ':pid', component: PmtIdProcessorComponent},
  { path: 'pids', component: PmtIdProcessorComponent},
  { path: 'pids/:pid', component: PmtIdProcessorComponent},
  { path: 'payment-processing/:pid', component: PaymentProcessingComponent },
  //RC_PS_6 This will route to the repay component
  { path: 'repay-processing/:pid', component: RepayPaymentProcessing }
  //{ path: 'repay-processing/pid', component: RepayPaymentProcessing} Testing Purposes
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

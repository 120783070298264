import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl,AbstractControl } from '@angular/forms';

export class CustomValidators {
  static required(control: FormControl) {
    return !control.value || (typeof control.value === 'string' && control.value.trim() === '') ?
      { required: true } : null;
  }
}

export function PasswordValidator(control: AbstractControl): { [key: string]: boolean } | null {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');
  if (password.pristine || confirmPassword.pristine) {
    return null;
  }
  return password && confirmPassword && password.value !== confirmPassword.value ? { 'misMatch': true } : null;
}

@Injectable()
export class UtilsService {

  constructor(private router: Router) { }

  matchOtherValidator(otherControlName: string) {

    let thisControl: FormControl;
    let otherControl: FormControl;

    return function matchOtherValidate(control: FormControl) {
      if (!control.parent) {
        return null;
      }

      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as FormControl;
        if (!otherControl) {
          throw new Error('matchOtherValidator(): other control is not found in parent group');
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }
      if (!otherControl) {
        return null;
      }
      if (otherControl.value !== thisControl.value) {
        return {
          matchOther: true
        };
      }
      return null;
    };
  }
  validateFormField(formField: any): boolean {
    return formField.valid || formField.untouched;
  }

  getTrimAddress(value: string) {
    if (!value || value.length <= 30) {
      return value;
    }
    const first30chars = value.substring(0, 30);
    const lastSpaceIndex = first30chars.lastIndexOf(' ');
    return (lastSpaceIndex >= 0) ? first30chars.substring(0, lastSpaceIndex) : first30chars;
  }
  fieldHasError(field: any, error: string): boolean {
    return field.errors && field.errors[error] && field.touched;
  }

}


import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { DataService } from '../shared/services/data.service';
import { eComplishIframeService } from '../shared/services/eComplish-iframe.service';
import { TokenResponse } from '../shared/constants/TokenResponse';
import { environment } from 'src/environments/environment';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Regexp_Patterns } from '../shared/constants/regex-patterns';
import { UtilsService, PasswordValidator } from '../shared/services/utils.service';
import { CreateAccountReq } from '../shared/constants/CreateAccountReq';
import { CreateAccountRes } from '../shared/constants/CreateAccountRes';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '../message.service';
import { EventType } from '../shared/constants/Event-Type';

@Component({
  selector: 'app-payment-processing',
  templateUrl: './payment-processing.component.html',
  styleUrls: ['./payment-processing.component.scss']
})
export class PaymentProcessingComponent implements OnInit {

  createAccountForm: FormGroup;
  existingAccountForm: FormGroup;

  iframeHost: string;
  urlSafe: SafeResourceUrl;
  private showFrame = false;
  private showInvalidPid = false;
  private showInvalidToken = false;
  private disableSubmitBtn = false;
  private usernameInuse = false;
  private showSubmitBtn = false;
  private paymentRequestInfo: any;
  private customerNumber = null;
  private storeNumber = null;
  private responseCode;
  private tokenInfo: TokenResponse;
  private createAccountReqObj: CreateAccountReq;
  private createAccountResObj: CreateAccountRes;
  private selectedRadio = true;
  private paymentToken;
  private token;
  private messageId;
  private initialPaymentResponseObj: any;
  private verifyAccountReqObj: any;
  private responseText = null;
  private createAccountFailed = false;



  constructor(
    public sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private eComplishIframeService: eComplishIframeService,
    public utilsService: UtilsService,
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private messageService: MessageService
  ) {
    this.handleeComplishCallback = this.handleeComplishCallback.bind(this);
  }

  ngOnInit() {
    const resp$ = this.route.paramMap.pipe(
      switchMap(params => {
        this.spinner.show('loading');
        return this.dataService.getRequestInformation(params.get('pid'));
      }
      ));
    if (resp$) {
      resp$.subscribe(response => {
        this.paymentRequestInfo = response;
        if (this.paymentRequestInfo && this.paymentRequestInfo.token) {
         // this.spinner.hide('loading');
          this.paymentToken = this.paymentRequestInfo.paymentTokenId ? this.paymentRequestInfo.paymentTokenId : null;
          this.token = this.paymentRequestInfo.token ? this.paymentRequestInfo.token : null;
          this.messageId = this.paymentRequestInfo.messageId ? this.paymentRequestInfo.messageId : null;
          this.storeNumber = this.paymentRequestInfo.storeId ? this.paymentRequestInfo.storeId : null;
          this.setStoreNumber();
          this.handleCreateAccount();
        }else {
          this.showSubmitBtn = false;
          const httpErrorResponse = response as HttpErrorResponse;
          this.showFrame = true;
          this.spinner.hide('loading');
          if (httpErrorResponse && httpErrorResponse.status) {
            const status = httpErrorResponse.status;
            if (status === 401 || status === 403) {
              this.processEcomCallbackWithParams(EventType.token_expired);
              this.showInvalidToken = true;
            } else {
              this.showInvalidPid = true;
            }
          } else {
            this.spinner.hide('loading');
            this.showFrame = false;
            this.showInvalidPid = true;
          }
        }
      }, (error) => {
        this.showSubmitBtn = false;
        console.log('err', error);
      });
    }
    this.selectedRadio = true;
    this.initForm();
    this.iframeHost = environment.eComplishHost;
  }

  initForm() {
    // this.options = true;
    this.createAccountForm = this.fb.group({
      username: ['', [Validators.required, Validators.pattern(Regexp_Patterns.Alphanumeric)]],
      password: ['', [Validators.required, Validators.pattern(Regexp_Patterns.password1)]],
      confirmPassword: ['', [Validators.required,]],
      securityQuestion: ['', [Validators.required,]],
      securityAnswer: ['', [Validators.required,]]
    },
      { validator: PasswordValidator },
    );
    this.existingAccountForm = this.formBuilder.group({
      existingUsername: ['', [Validators.required, Validators.pattern(Regexp_Patterns.Alphanumeric)]],
      existingPassword: ['', [Validators.required]]
    });
  }

  setStoreNumber(){
    if(this.storeNumber.toString().length < 5)
    this.storeNumber = "0" + this.storeNumber.toString();    
  }

  setradio(e: boolean): void {
    this.selectedRadio = e;

  }
  ifUsernameChanges(e:any){
    this.usernameInuse = false;
    this.disableSubmitBtn = false;
  }
  
  isSelected(name: boolean): boolean {
    if (!this.selectedRadio) {
      return false;
    }
    return (this.selectedRadio); // if current radio button is selected, return true, else return false  
  }
  validateField(fieldName: string): boolean {
    return this.utilsService.validateFormField(this.createAccountForm.get(fieldName));
  }

  validateExistingField(fieldName: string): boolean {
    return this.utilsService.validateFormField(this.existingAccountForm.get(fieldName));
  }

  handleCreateAccount() {
    this.messageService.clear();
    this.dataService.createAccountMethod(this.paymentToken, this.token, this.messageId)
      .subscribe(createAccountResponse => {
        this.spinner.hide('loading');
        this.createAccountResObj = createAccountResponse as CreateAccountRes;
        if(this.createAccountResObj.StatusCode){
        if (this.createAccountResObj.StatusCode === '200')
          this.handleCreateAccountResponse(this.createAccountResObj);
        } else {
          this.createAccountFailed = true;
            const httpErrorResponse = createAccountResponse as HttpErrorResponse;
            if (httpErrorResponse && httpErrorResponse.status) {
              const status = httpErrorResponse.status;
              if (status === 400 && httpErrorResponse.error.StatusCodeDetail === 'Name in use. Choose another') {
                this.usernameInuse = true;
               // this.disableSubmitBtn = true;  
          }
          else {
          
            if(httpErrorResponse.error || httpErrorResponse.error != null || httpErrorResponse.error != undefined)
            {

            let msg = 'Error while loading. Please try again later.';
            if(httpErrorResponse.error.StatusCodeDetail || httpErrorResponse.error.StatusCodeDetail != null || httpErrorResponse.error.StatusCodeDetail != undefined)
            {
              this.messageService.add( msg + httpErrorResponse.error.StatusCodeDetail);
            }
            else 
            this.messageService.add( msg );
          }
        }
        }
      }
      }, (error) => {
        let msg = 'Error while loading. Please try again later.';
      this.messageService.add(msg);
      console.log('err', error);
     // throw error;
      });
  }

  handleCreateAccountResponse(ESBResponse) {
    console.log(ESBResponse);
    if (ESBResponse && ESBResponse.CustomerAccountID) {
      this.customerNumber = ESBResponse.CustomerAccountID ? ESBResponse.CustomerAccountID : "";
      this.showFrame = true;
      this.spinner.hide('suc');
      this.showSubmitBtn = true;
      this.loadIframe();
    }
  }

  handleVerifyAccount(e: any){
    this.messageService.clear();
    this.spinner.show('verifying');
    this.verifyAccountReqObj = new CreateAccountReq();
    this.verifyAccountReqObj.userName = this.existingAccountForm.get('existingUsername').value;
    this.verifyAccountReqObj.password = this.existingAccountForm.get('existingPassword').value;
    this.dataService.verifyAccountMethod(this.verifyAccountReqObj, this.paymentToken, this.token, this.messageId)
      .subscribe(createAccountResponse => {
        this.spinner.hide('verifying');
        this.createAccountResObj = createAccountResponse as CreateAccountRes;
        if (this.createAccountResObj.StatusCode === '200'){
          this.handleCreateAccountResponse(this.createAccountResObj);
        }
          else {
            const httpErrorResponse = createAccountResponse as HttpErrorResponse;
            let msg = 'Error while verifying the account. Please try again later.';
            if (httpErrorResponse && httpErrorResponse.status) {
             if((httpErrorResponse.error || httpErrorResponse.error != null || httpErrorResponse.error != undefined) &&
               (httpErrorResponse.error.StatusCodeDetail || httpErrorResponse.error.StatusCodeDetail != null || httpErrorResponse.error.StatusCodeDetail != undefined))
              this.messageService.add(msg + httpErrorResponse.error.StatusCodeDetail);
            }
            else {
              this.messageService.add(msg);
            }
          }
      }, (error) => {
        let msg = 'Error while verifying the account. Please try again later. ';
        this.messageService.add(msg);
        console.log(error);
      });
   }

  loadIframe() {
    let path = this.iframeHost + "pageID=1573686004739&allowACH=N&lang=en&customerNumber=" +
      this.customerNumber + "&storeNumber=" + this.storeNumber;
    console.log(" iframe source ..." + path);
    // hard code path
   // path = this.iframeHost + "pageID=1573686004739&allowACH=N&lang=en&customerNumber=HRmba0029070&storeNumber=09042";
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(path);
  }
  handleIframeSubmit(e: any): void {
   this.spinner.show('saving');
    this.eComplishIframeService.getPageRegID(e, this.handleeComplishCallback, this.customerNumber, this.storeNumber);
   // this.eComplishIframeService.getPageRegID(e, this.handleeComplishCallback, "HRmba0029070", "09042");
  }

  handleeComplishCallback(response: any): void {
    this.spinner.hide('saving');
    this.responseCode = response.responseCode;
    this.responseText = response.responseText;
    if (this.responseCode === '1') {
      this.tokenInfo = new TokenResponse(response);
    }
    // workaround to register the callback
    if (document.getElementById('workaround') !== null) {
      document.getElementById('workaround').click();
    }
  }
  processEcomplishCallback(){
    this.messageService.clear();
    if (this.responseCode === '1') {
      this.spinner.show('suc');
      //const username = this.createAccountReqObj ? this.createAccountReqObj.userName : this.verifyAccountReqObj.userName;
     // const password = this.createAccountReqObj ? this.createAccountReqObj.password : this.verifyAccountReqObj.password;
      this.handleInitialPayment(this.tokenInfo);
    }
    else if(this.responseCode === '2')
    this.messageService.add( this.responseText + ' Please check and re-enter your credit card number and try again.');
    else if(this.responseCode === '9')
    this.messageService.add(this.responseText + ' Please check and re-enter card details and try again.');
    else
    this.messageService.add('Please check and re-enter your credit card number and try again.');
  }
  handleInitialPayment(cardInfo) {
    /*
    this.messageService.clear();
    this.dataService.initialPaymentMethod(cardInfo,this.paymentToken, this.token, this.messageId)
      .subscribe(initialPaymentResponse => {
        this.spinner.hide('suc');
        this.initialPaymentResponseObj = initialPaymentResponse;
        if (this.initialPaymentResponseObj.StatusCode === '200'){
          this.handleInitialPaymentResponse(this.initialPaymentResponseObj);
        }
          else {
            const httpErrorResponse = initialPaymentResponse as HttpErrorResponse;
            let msg = 'Error while processing the payment. Please try again later.';
            if (httpErrorResponse && httpErrorResponse.status) {
             if((httpErrorResponse.error || httpErrorResponse.error != null || httpErrorResponse.error != undefined) &&
               (httpErrorResponse.error.StatusCodeDetail || httpErrorResponse.error.StatusCodeDetail != null || httpErrorResponse.error.StatusCodeDetail != undefined))
              this.messageService.add(msg + httpErrorResponse.error.StatusCodeDetail);
            }
            else {
              this.messageService.add(msg);
            }
          }
      }, (error) => {
        //let msg = 'Error while processing the payment. Please try again later.';
      // this.messageService.add(msg);
      this.processEcomCallbackWithParams(EventType.perm_error);
        console.log(error);
      });
      */
  }

  handleInitialPaymentResponse(Response) {
    console.log(Response);
    if (Response && Response.StatusCode === '200') {
     //if(Response.TransactionID && (Response.TransactionID != null || Response.TransactionID != undefined))
      this.processEcomCallbackWithParams(EventType.success);
      // process call back with Params
    }
    else 
    {
      let msg = 'Error while processing the payment. Please try again later.';
    this.messageService.add(msg);
    }
  }

  processEcomCallbackWithParams(eventType: EventType) {
    let callbackURL = this.paymentRequestInfo.callbackURL;
    let navigateUrl = null;
    const substring = "?";
    if (callbackURL && !callbackURL.startsWith('http://') && !callbackURL.startsWith('https://')) {
      callbackURL = 'https://' + callbackURL;
    }
    if(callbackURL.includes(substring)){
      navigateUrl = callbackURL
      + '&customerid=' + this.paymentRequestInfo.partyId
      + '&event_type=' + eventType;
    }
    else
    navigateUrl = callbackURL
      + '?customerid=' + this.paymentRequestInfo.partyId
      + '&event_type=' + eventType;
    window.location.href = navigateUrl;
  }

  get disableBtn() {
    return this.disableSubmitBtn;
  }

  get invalidCustomer() {
    return this.createAccountFailed;
  }

}

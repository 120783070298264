import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { VantivIframeService, VantivConfig } from './shared/services/vantiv-iframe.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DataService } from './shared/services/data.service';
import { UrlService } from './shared/services/url.service';
import { HttpClientModule } from '@angular/common/http';
import { PmtIdProcessorComponent } from './pmt-id-processor/pmt-id-processor.component';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MessageComponent } from './message/message.component';
import { MessageService } from './message.service';
import { PaymentProcessingComponent } from './payment-processing/payment-processing.component';
import { eComplishIframeService, eComplishConfig } from './shared/services/eComplish-iframe.service';
import { RepayPaymentProcessing, } from './repay-payment-processor/repay-payment-processor.component';
import { RepayIframeService } from './shared/services/repay-iframe.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsService } from './shared/services/utils.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import { 
  MatToolbarModule, 
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule ,
  MatStepperModule,
  MatInputModule
} from '@angular/material';



@NgModule({
  declarations: [
    AppComponent,
    PmtIdProcessorComponent,
    MessageComponent,
    PaymentProcessingComponent,
    RepayPaymentProcessing
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule
  ],
  exports: [
    MatButtonModule,
    MatInputModule
  ],
  providers: [
    VantivIframeService,
    VantivConfig,
    DataService,
    MessageService,
    eComplishIframeService,
    eComplishConfig,
    UrlService,
    UtilsService,
    RepayIframeService,
    MatDatepickerModule,
    MatNativeDateModule,
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
          window.location.href = (route.data as any).externalUrl;
      } 
  },
  {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }


export const CreditCardsTypes = [
  {
    id: '1',
    value: 'MasterCard',
    short: 'MC',
    abbreviation: 'M'
  },
  {
    id: '2',
    value: 'Visa',
    short: 'VI',
    abbreviation: 'V'
  },
  {
    id: '3',
    value: 'American Express',
    short: 'AX',
    abbreviation: 'A'
  },
  {
    id: '4',
    value: 'Discover',
    short: 'DI',
    abbreviation: 'D'
  },
  {
    id: '5',
    value: 'Diner\'s Club',
    short: 'CD',
    abbreviation: 'DC'
  },
  {
    id: '6',
    value: 'JCB',
    short: 'JC',
    abbreviation: 'J'
  },
  {
    id: '7',
    value: 'PayPal',
    short: 'PP',
    abbreviation: 'PP'
  },
  {
    id: '8',
    value: 'PayPal Credit',
    short: 'BL',
    abbreviation: 'BL'
  },
  {
    id: '9',
    value: 'eCheck',
    short: 'EC',
    abbreviation: 'EC'
  },
  {
    id: '10',
    value: 'Gift Card',
    short: 'GC',
    abbreviation: 'GC'
  },
  {
    id: '11',
    value: '',
    short: '',
    abbreviation: ''
  },
  {
    id: '12',
    value: 'Personal Checking',
    short: '',
    abbreviation: ''
  },
  {
    id: '13',
    value: 'Personal Savings',
    short: '',
    abbreviation: ''
  },
  {
    id: '14',
    value: 'Corporate Checking',
    short: '',
    abbreviation: ''
  },
  {
    id: '15',
    value: 'Corporate Savings',
    short: '',
    abbreviation: ''
  }
];



export class HeaderItem
{
    Key:string;
    Value:string;
    constructor(key,value)
    {
        this.Key = key;
        this.Value = value;
    }
   
}

export interface ITokenResponse {
    customerNumber: string;
    lastFour: string;
    orderID: string;
    pageRegistrationID: string;
    paymentMethod : string;
    paymentSubMethod: string;
    responseCode: string;
    responseText: string;
    storeNumber: string;
    tokenizedPAN: string;
}

export class TokenResponse implements ITokenResponse {

    customerNumber: string;
    lastFour: string;
    orderID: string;
    pageRegistrationID: string;
    paymentMethod : string;
    paymentSubMethod: string;
    responseCode: string;
    responseText: string;
    storeNumber: string;
    tokenizedPAN: string;

    constructor(response = null) {
        this.customerNumber = response && response.customerNumber || null;
        this.lastFour = response && response.lastFour || null;
        this.orderID = response && response.orderID || null;
        this.pageRegistrationID = response && response.pageRegistrationID || null;
        this.paymentMethod = response && response.paymentMethod || null;
        this.responseCode = response && response.responseCode || null;
        this.responseText = response && response.responseText || null;
        this.storeNumber = response && response.storeNumber || null;
        this.tokenizedPAN = response && response.tokenizedPAN || null;
    }
}
